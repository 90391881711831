import EventEmitter from 'events';

import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react';

import { ColorScheme, Scale } from '~/services/hub/models/User';

type DeviceId = string | 'default';

export interface Preferences {
  colorScheme: ColorScheme;
  scale: Scale;
  noiseCancellation: boolean;
  microphone: DeviceId;
  speaker: DeviceId;
  ringtone: DeviceId;
}

export interface PreferencesStorage {
  get(key: string): string | null;
  set(key: string, value: string): void;
}

interface StorageAdapterOptions {
  storage: PreferencesStorage;
  default: Preferences;
}

class StorageAdapter extends EventEmitter implements Preferences {
  #default: Preferences;
  #storage: PreferencesStorage;

  constructor(options: StorageAdapterOptions) {
    super();

    this.#default = options.default;
    this.#storage = options.storage;

    const pds = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));

    for (const [pdname, pdprop] of Object.entries(pds)) {
      if (pdprop.get) {
        Object.defineProperty(this, pdname, {
          ...pdprop,
          enumerable: true
        });
      }
    }
  }

  get colorScheme(): ColorScheme {
    const item = this.#storage.get(`colorScheme`);
    return item
      ? ColorScheme[item as keyof typeof ColorScheme]
      : this.#default.colorScheme;
  }

  get scale(): Scale {
    const item = this.#storage.get(`scale`);
    return item ? Scale[item as keyof typeof Scale] : this.#default.scale;
  }

  get noiseCancellation(): boolean {
    const item = this.#storage.get(`noiseCancellation`);

    if (item === 'false') {
      return false;
    }

    if (item === 'true') {
      return true;
    }

    return this.#default.noiseCancellation;
  }

  get microphone(): DeviceId {
    return this.#storage.get(`microphone`) ?? this.#default.microphone;
  }

  get speaker(): DeviceId {
    return this.#storage.get(`speaker`) ?? this.#default.speaker;
  }

  get ringtone(): DeviceId {
    return this.#storage.get(`ringtone`) ?? this.#default.ringtone;
  }

  set(key: string, value: unknown) {
    this.#storage.set(`${key}`, String(value));
  }
}

type UpdateFunctionArgs = Action extends infer ActionIterator
  ? ActionIterator extends { [key in infer Key]: infer Value }
    ? [key: Key, value: Value]
    : never
  : never;

type UpdateFunction = { (...args: UpdateFunctionArgs): void };

export const PreferencesContext = createContext<
  [preferences: Preferences, update: UpdateFunction]
>([
  {
    colorScheme: ColorScheme.SYSTEM,
    scale: Scale.MEDIUM,
    noiseCancellation: true,
    microphone: 'default',
    speaker: 'default',
    ringtone: 'default'
  },
  () => {
    /** SSR stub method. */
  }
]);

type PreferencesProviderProps = {
  children: ReactNode;
  storage: PreferencesStorage;
  default?: Partial<Preferences>;
};

export function PreferencesProvider(props: PreferencesProviderProps) {
  const { children } = props;

  const [context] = useContext(PreferencesContext);

  const storage = useMemo(() => {
    return new StorageAdapter({
      storage: props.storage,
      default: { ...context, ...props.default }
    });
  }, [context, props.default, props.storage]);

  const [preferences, dispatch] = useReducer(
    reducer,
    Object.assign({}, storage)
  );

  const value = useMemo<[preferences: Preferences, update: UpdateFunction]>(
    () => [
      preferences,
      (key: string, value: unknown) => {
        storage.set(key, value);
        dispatch({ [key]: value } as Action);
      }
    ],
    [preferences, storage]
  );

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
}

type Action = keyof Preferences extends infer Key
  ? Key extends keyof Preferences
    ? { [key in Key]: Preferences[Key] }
    : never
  : never;

function reducer(state: Preferences, action: Action): Preferences {
  return { ...state, ...action };
}
