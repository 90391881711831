import { CloseFunction, ToastQueue } from '@diallink-corp/convergo-react-toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function usePhoneSystemServerHealth() {
  const [healthy, setHealthy] = useState(true);

  const abortController = useRef<AbortController>();

  async function check() {
    if (abortController.current) abortController.current.abort('Cancelled');

    abortController.current = new AbortController();
    const { ok, status } = await fetch('/api/ping', {
      signal: abortController.current.signal
    });
    if ([502, 504, 429].includes(status)) {
      setHealthy(false);
    } else if (ok) {
      setHealthy(true);
    }
  }

  useEffect(() => {
    const interval = setInterval(check, 30_000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  return { healthy };
}

export function ServerHealthMonitor() {
  const { t } = useTranslation('common');

  const [toast, setToast] = useState<{ close: CloseFunction }>();

  const { healthy } = usePhoneSystemServerHealth();

  useEffect(() => {
    if (healthy) {
      return toast?.close();
    }

    if (!toast) {
      setToast({ close: ToastQueue.neutral(t('servers-seem-busy')) });
    }
  }, [healthy, t, toast]);

  return null;
}
