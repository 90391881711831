import { useRevalidator } from '@remix-run/react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo
} from 'react';

import { NetworkInformationContext } from './NetworkInformationContext';

export type RevalidationState = 'idle' | 'loading';

export const RevalidatorContext = createContext({
  state: 'idle' as RevalidationState,
  revalidate() {}
});

export interface RevalidatorProviderOptions {
  children: ReactNode;
}

export function RevalidatorProvider({ children }: RevalidatorProviderOptions) {
  const revalidator = useRevalidator();
  const network = useContext(NetworkInformationContext);

  let abortController = new AbortController();

  const revalidate = useCallback(async () => {
    if (!network.onLine) return;

    try {
      abortController.abort('Cancelled');
      abortController = new AbortController();

      AbortSignal.timeout(1000).onabort = () => {
        abortController.abort('Timed out');
      };

      const { ok } = await fetch('/api/ping', {
        signal: abortController.signal
      });

      if (ok) {
        revalidator.revalidate();
      }
    } catch {
      // Ignore errors.
    }
  }, [revalidator.revalidate]);

  const value = useMemo(() => {
    return {
      revalidate,
      state: revalidator.state
    };
  }, [revalidator.revalidate, revalidator.state]);

  return (
    <RevalidatorContext.Provider value={value}>
      {children}
    </RevalidatorContext.Provider>
  );
}
