import EventEmitter from 'events';

import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

type EventMap = {
  online: [];
  offline: [];
};

const navigator = typeof window === 'undefined' ? null : window.navigator;

const networkEventEmitter = new EventEmitter<EventMap>();

export const NetworkInformationContext = createContext({
  onLine: navigator?.onLine ?? false,
  on(event: keyof EventMap, listener: () => void) {
    networkEventEmitter.on(event, listener);
  },
  off(event: keyof EventMap, listener: () => void) {
    networkEventEmitter.off(event, listener);
  }
});

let globalAbortController = new AbortController();

export function NetworkInformationProvider({
  children
}: Omit<ProviderProps<object>, 'value'>) {
  const network = useContext(NetworkInformationContext);
  const [onLine, setOnLine] = useState(network.onLine);

  useEffect(function listenWindowOnLineEvent() {
    async function handleOnline() {
      const scopeAbortController = new AbortController();

      globalAbortController.signal.onabort = () => scopeAbortController.abort();
      //   AbortSignal.timeout(1_000).onabort = () => scopeAbortController.abort();

      /**
       * For more details @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
       * ...while you can assume that the browser is offline when it returns a false value, you cannot assume that a true value necessarily means that the browser can access the internet.
       * You could be getting false positives, such as in cases where the computer is running a virtualization software that has virtual ethernet adapters that are always "connected."
       * Therefore, if you really want to determine the online status of the browser, you should develop additional means for checking.
       */
      try {
        const { ok } = await fetch('/api/ping', {
          signal: scopeAbortController.signal
        });

        if (ok) {
          networkEventEmitter.emit('online');
          setOnLine(window.navigator.onLine);
        } else {
          throw new Error('Ping request failed');
        }
      } catch {
        scopeAbortController.abort();
        setTimeout(handleOnline, 1_000);
      }
    }

    async function handleOffline() {
      globalAbortController.abort();
      globalAbortController = new AbortController();

      networkEventEmitter.emit('offline');
      setOnLine(false);
    }

    window.addEventListener('online', handleOnline, { capture: true });
    window.addEventListener('offline', handleOffline, { capture: true });

    // TODO: This is a workaround. The 'offline' event isn't fired when the page is frozen due to the hibernation or suspension.
    document.addEventListener('resume', handleOnline, { capture: true });
    document.addEventListener('freeze', handleOffline, { capture: true });

    return function cleanup() {
      window.removeEventListener('online', handleOnline, { capture: true });
      window.removeEventListener('offline', handleOffline, { capture: true });
      document.removeEventListener('resume', handleOnline, {
        capture: true
      });
      document.removeEventListener('freeze', handleOffline, {
        capture: true
      });
    };
  }, []);

  const value = useMemo(() => ({ ...network, onLine }), [network, onLine]);

  return (
    <NetworkInformationContext.Provider value={value}>
      {children}
    </NetworkInformationContext.Provider>
  );
}
